import convict from 'convict';
import * as convictFormatWithValidator from 'convict-format-with-validator';

convict.addFormat(convictFormatWithValidator.url);

function assert(assertion: Boolean, msg: string)
{
	if (!assertion)
		throw new Error(msg);
}

convict.addFormat({
	name     : 'float',
	coerce   : (v: string) => parseFloat(v),
	validate : (v: number) => assert(Number.isFinite(v), 'must be a number')
});

/**
 * The medVC configuration schema.
 *
 * Use `yarn gen-config-docs` to re-generate the README.md and the
 * public/config/config.example.js files.
 */
const configSchema = convict({
	loginEnabled :
	{
		doc     : 'If the login is enabled.',
		format  : 'Boolean',
		default : true
	},
	consultantsEnabled :
	{
		doc     : 'If the consultants are enabled.',
		format  : 'Boolean',
		default : false
	},
	developmentPort :
	{
		doc     : 'The development server listening port.',
		format  : 'port',
		default : 3443
	},
	productionPort :
	{
		doc     : 'The production server listening port.',
		format  : 'port',
		default : 443
	},
	maxExtraVideos :
	{
		doc     : 'The of extra video producers allowed.',
		format  : 'nat',
		default : 2
	},
	maxExtraVideosForClientId :
	{
		doc     : 'Maps the maxExtraVideos depending on the clientdId.',
		format  : Object,
		default : {}
	},
	showDeviceLabelForClientId :
	{
		doc     : 'Maps if to show device label depending on the clientdId.',
		format  : Object,
		default : {}
	},
	serverHostname :
	{
		doc      : 'If the server component runs on a different host than the app you can specify the host name.',
		format   : 'String',
		default  : '',
		nullable : true
	},
	closeUrl :
	{
		doc      : 'url to go to after meeting closed. can be path /xxx or full url. if null, will go to room start page.',
		format   : 'String',
		nullable : true,
		default  : null
	},
	closeUrlPin :
	{
		doc      : 'url to go to after meeting closed. can be path /xxx or full url. For room owner. if null, will go to room start page.',
		format   : 'String',
		nullable : true,
		default  : null
	},
	closeUrlTerminal :
	{
		doc      : 'url to go to after meeting closed. can be path /xxx or full url. On medVC termianl only. if null, will go to room start page.',
		format   : 'String',
		nullable : true,
		default  : 'http://127.0.0.1/medVCAddressBook/start.php'
	},
	terminalUrl :
	{
		doc     : 'url to medVC terminal API',
		format  : 'String',
		default : 'http://127.0.0.1:5000'
	},

	addressBookAllowManualUrl :
	{
		doc     : 'If true, allow manual entry of room url.',
		format  : 'Boolean',
		default : false
	},

	/**
	 * Supported browsers version in bowser satisfy format.
	 * See more:
	 * https://www.npmjs.com/package/bowser#filtering-browsers
	 * Otherwise you got a unsupported browser page
	 */
	supportedBrowsers :
	{
		doc     : 'Supported browsers version in bowser satisfy format.',
		format  : Object,
		default :
		{
			'microsoft edge' : '>=108',
			'safari'         : '>=14.5',
			'firefox'        : '>=106',
			'chrome'         : '>=108',
			'chromium'       : '>=108',
			'opera'          : '>=96'
		}
	},
	forceTcp :
	{
		doc     : 'If true, force TCP enabled for all clients.',
		format  : 'Boolean',
		default : false
	},

	/*
	Example value
	[ {start : '78.11.0.0', end : '78.11.255.255' } ]
	*/
	forceTcpForIps :
	{
		doc     : 'Ip ranges to force TCP from clients from ranges',
		format  : Array,
		default : []
	},
	forceTurn :
	{
		doc     : 'If true, force TURN enabled for all clients.',
		format  : 'Boolean',
		default : false
	},

	/*
	Example value
	[ {start : '78.11.0.0', end : '78.11.255.255' } ]
	*/
	forceTurnForIps :
	{
		doc     : 'Ip ranges to force TURN from clients from ranges',
		format  : Array,
		default : []
	},

	/**
	 * Network priorities 
	 * DSCP bits set by browser according this priority values. 
	 * ("high" means actually: EF for audio, and AF41 for Video in chrome)
	 * https://en.wikipedia.org/wiki/Differentiated_services
	 */
	networkPriorities :
	{
		doc     : 'Network priorities.',
		format  : Object,
		default :
		{
			audio       : 'high',
			mainVideo   : 'high',
			extraVideo  : 'high',
			screenShare : 'medium'
		}
	},
	languagesAvailable :
	{
		doc     : 'The list of available languages for the user.',
		format  : Array,
		default : [
			'en',
			'pl',
			'de',
			'es',
			'it',
			'fr',
			'bg'
		]
	},
	pipUrl :
	{
		doc     : 'For medVC terminals URL that opens PiP manager. The URL must have the serverId param given.',
		format  : 'String',
		default : null
	},
	autoJoinWithPin :
	{
		doc     : 'If enabled url with pin will cause to auto join room.',
		format  : 'Boolean',
		default : true
	},

	// The aspect ratio of the videos as shown on the screen. 
	// This is changeable in client settings.
	// This value must match one of the defined values in
	// aspectRatios EXACTLY (e.g. 1.333)
	aspectRatio :
	{
		doc : `The aspect ratio of the videos as shown on the screen.
This value must match exactly one of the values defined in aspectRatios.`,
		format  : 'float',
		default : 16 / 9
	},
	aspectRatios :
	{
		doc     : 'The selectable aspect ratios in the user settings.',
		format  : Array,
		default :
		[
			{
				value : 4 / 3, // 4 / 3
				label : '4 : 3'
			},
			{
				value : 16 / 9, // 16 / 9
				label : '16 : 9'
			}
		]
	},
	resolutionsAvailable :
	{
		doc     : 'The list of resolutions available for this deployment. ultra is disabled by default.',
		format  : Array,
		default : [
			'low',
			'medium',
			'high',
			'veryhigh'
		]
	},
	resolutionPin :
	{
		doc     : 'The default video camera capture resolution.',
		format  : [ 'low', 'medium', 'high', 'veryhigh', 'ultra' ],
		default : 'veryhigh'
	},
	resolutionPinMobile :
	{
		doc     : 'The default video camera capture resolution.',
		format  : [ 'low', 'medium', 'high', 'veryhigh', 'ultra' ],
		default : 'high'
	},
	frameRatePin :
	{
		doc     : 'The default video camera capture framerate.',
		format  : 'nat',
		default : 25
	},
	resolution :
	{
		doc     : 'The default video camera capture resolution.',
		format  : [ 'low', 'medium', 'high', 'veryhigh', 'ultra' ],
		default : 'high'
	},
	resolutionMobile :
	{
		doc     : 'The default video camera capture resolution.',
		format  : [ 'low', 'medium', 'high', 'veryhigh', 'ultra' ],
		default : 'medium'
	},
	resolutionsPerRoom :
	{
		doc     : 'Maps the resolution per room for non PIN users - large meetings.',
		format  : Object,
		default : {}
	},
	resolutionsMobilePerRoom :
	{
		doc     : 'Maps the resolution for mobile per room for non PIN users - large meetings.',
		format  : Object,
		default : {}
	},
	videoTrackContentHint :
	{
		doc     : 'The hind for encoder about.',
		format  : [ '', 'motion', 'detail', 'text' ],
		default : ''
	},
	frameRate :
	{
		doc     : 'The default video camera capture framerate.',
		format  : 'nat',
		default : 25
	},
	frameRatesAvailable :
	{
		doc     : 'The avaliable video camera capture framerates to choose from.',
		format  : Array,
		default : [ 15, 25, 30 ]
	},
	frameRateProblem :
	{
		doc     : 'The video camera capture framerate when problem.',
		format  : 'nat',
		default : 25
	},
	blurBackground :
	{
		doc     : 'If enabled the settings option to blur backgroup for webcam video is available in menu.',
		format  : 'Boolean',
		default : false
	},
	screenResolution :
	{
		doc     : 'The default screen sharing resolution.',
		format  : [ 'low', 'medium', 'high', 'veryhigh', 'ultra' ],
		default : 'veryhigh'
	},
	screenSharingTrackContentHint :
	{
		doc     : 'The hind for encoder about.',
		format  : [ '', 'motion', 'detail', 'text' ],
		default : ''
	},
	screenSharingFrameRate :
	{
		doc     : 'The default screen sharing framerate.',
		format  : 'nat',
		default : 25
	},
	screenSharingFrameRatesAvailable :
	{
		doc     : 'The avaliable screen sharing framerates to choose from.',
		format  : Array,
		default : [ 5, 15, 25, 30 ]
	},
	producersStatsIntervalTime :
	{
		doc     : 'Time in ms between checks of producer stats.',
		format  : 'nat',
		default : 2000
	},
	statsScoresExchangeIntervalTime :
	{
		doc     : 'Time in ms between exchange of stats and scores with server.',
		format  : 'nat',
		default : 2000
	},
	videoFilterBlurPixels :
	{
		doc     : 'Test feature - blur filter pixel size',
		format  : 'nat',
		default : 5
	},
	videoCodec :
	{
		doc     : 'Video codec to use for webcam and extra videos sharing',
		format  : 'String',
		default : 'VP9'
	},
	videoCodecsAvailable :
	{
		doc     : 'The avaliable webcam and extra videos codecs to choose from.',
		format  : Array,
		default : [ 'VP9', 'VP8', 'H264' ]
	},
	screenSharingCodec :
	{
		doc     : 'Video codec to use for screen sharing',
		format  : 'String',
		default : 'VP9'
	},
	screenSharingCodecsAvailable :
	{
		doc     : 'The avaliable screen sharing codecs to choose from.',
		format  : Array,
		default : [ 'VP9', 'VP8', 'H264' ]
	},
	simulcast :
	{
		doc     : 'Enable or disable simulcast for webcam and extra videos when not using VP9 SVC.',
		format  : 'Boolean',
		default : true
	},
	simulcastSharing :
	{
		doc     : 'Enable or disable simulcast for screen sharing video when not using VP9 SVC.',
		format  : 'Boolean',
		default : false
	},
	codecOptions :
	{
		doc     : 'Define CodeOptions object.',
		format  : Object,
		default :
		{
			videoGoogleStartBitrate : 1000
		}
	},
	codecOptionsUltra :
	{
		doc     : 'Define CodeOptions object for UHD resolution. UHD does not use simulcast.',
		format  : Object,
		default :
		{
			// videoGoogleMinBitrate   : 1000,
			videoGoogleStartBitrate : 1000,
			videoGoogleMaxBitrate   : 12000
		}
	},
	svcProfiles :
	{
		doc     : 'Define different encodings for various resolutions of the video using VP9 SVC.',
		format  : Object,
		default :
		{
			3840 : { scalabilityMode: 'L1T1', maxBitrate: 12000000 },
			1920 : { scalabilityMode: 'L3T2_KEY', maxBitrate: 10000000 },
			1280 : { scalabilityMode: 'L3T2_KEY', maxBitrate: 6000000 },
			640  : { scalabilityMode: 'L2T2_KEY', maxBitrate: 2000000 },
			320  : { scalabilityMode: 'L1T1', maxBitrate: 500000 }
		}
	},
	svcProfilesMobile :
	{
		doc     : 'Mobile client - Define different encodings for various resolutions of the video using VP9 SVC.',
		format  : Object,
		default :
		{
			3840 : { scalabilityMode: 'L1T1', maxBitrate: 8000000 },
			1920 : { scalabilityMode: 'L1T1', maxBitrate: 5000000 },
			1280 : { scalabilityMode: 'L1T1', maxBitrate: 3000000 },
			640  : { scalabilityMode: 'L1T1', maxBitrate: 1500000 },
			320  : { scalabilityMode: 'L1T1', maxBitrate: 500000 }
		}
	},
	enableProblemAutoSwitching :
	{
		doc     : 'If set to true in case of performance problems the encoder will switch to problem mode.',
		format  : 'Boolean',
		default : true
	},
	svcProfilesProblem :
	{
		doc     : 'Problem connection - Define different encodings for various resolutions of the video using VP9 SVC.',
		format  : Object,
		default :
		{
			3840 : { scalabilityMode: 'L1T1', maxBitrate: 10000000 },
			1920 : { scalabilityMode: 'L1T1', maxBitrate: 6000000 },
			1280 : { scalabilityMode: 'L1T1', maxBitrate: 4500000 },
			640  : { scalabilityMode: 'L1T1', maxBitrate: 1500000 },
			320  : { scalabilityMode: 'L1T1', maxBitrate: 500000 }
		}
	},
	videoCodecProblem :
	{
		doc     : 'Problem connection - Video codec to use for webcam and extra videos sharing',
		format  : 'String',
		default : 'VP9'
	},
	resolutionProblem :
	{
		doc     : 'The default video camera capture resolution.',
		format  : [ 'low', 'medium', 'high', 'veryhigh', 'ultra' ],
		default : 'high'
	},
	svcProfiles3d :
	{
		doc     : 'Define different encodings for various resolutions of the video using VP9 SVC for 3D rooms.',
		format  : Object,
		default :
		{
			3840 : { scalabilityMode: 'L1T1', maxBitrate: 18000000 },
			1920 : { scalabilityMode: 'L1T1', maxBitrate: 12000000 },
			1280 : { scalabilityMode: 'L1T1', maxBitrate: 8000000 },
			640  : { scalabilityMode: 'L1T1', maxBitrate: 3000000 },
			320  : { scalabilityMode: 'L1T1', maxBitrate: 1000000 }
		}
	},
	svcScreenSharingProfile :
	{
		doc     : 'Define different encodings for screen sharing using VP9 SVC.',
		format  : Object,
		default :
		{ scalabilityMode: 'L1T1', maxBitrate: 12000000, dtx: true }
	},
	simulcastProfiles :
	{
		doc     : 'Define different encodings for various resolutions of the video.',
		format  : Object,
		default :
		{
			3840 :
				[
					{ scaleResolutionDownBy: 6, maxBitRate: 750000 },
					{ scaleResolutionDownBy: 2, maxBitRate: 6000000 },
					{ scaleResolutionDownBy: 1, maxBitRate: 12000000 }
				],
			1920 :
				[
					{ scaleResolutionDownBy: 6, maxBitRate: 750000 },
					{ scaleResolutionDownBy: 2, maxBitRate: 3000000 },
					{ scaleResolutionDownBy: 1, maxBitRate: 6000000 }
				],
			1280 :
				[
					{ scaleResolutionDownBy: 4, maxBitRate: 250000 },
					{ scaleResolutionDownBy: 2, maxBitRate: 900000 },
					{ scaleResolutionDownBy: 1, maxBitRate: 3000000 }
				],
			640 :
				[
					{ scaleResolutionDownBy: 2, maxBitRate: 250000 },
					{ scaleResolutionDownBy: 1, maxBitRate: 750000 }
				],
			320 :
				[
					{ scaleResolutionDownBy: 1, maxBitRate: 250000 }
				]
		}
	},
	simulcastProfiles3d :
	{
		doc     : 'Define different encodings for various resolutions of the video in 3D.',
		format  : Object,
		default :
		{
			3840 :
				[
					{ scaleResolutionDownBy: 1, maxBitRate: 18000000 }
				],
			1920 :
				[
					{ scaleResolutionDownBy: 1, maxBitRate: 12000000 }
				],
			1280 :
				[
					{ scaleResolutionDownBy: 1, maxBitRate: 8000000 }
				],
			640 :
				[
					{ scaleResolutionDownBy: 1, maxBitRate: 3000000 }
				],
			320 :
				[
					{ scaleResolutionDownBy: 1, maxBitRate: 1000000 }
				]
		}
	},
	recorderMarkerOffset :
	{
		doc     : 'Offset in seconds for placing marker in recording. Should be 0 or negative',
		format  : 'nat',
		default : -1
	},
	recorderShowStats :
	{
		doc     : 'Enable statistics for debug on recorder.',
		format  : 'Boolean',
		default : false
	},
	recorderSpeakerMaxLayers :
	{
		doc     : 'Enable max layers for speaker for recorder.',
		format  : 'Boolean',
		default : false
	},
	localRecordingEnabled :
	{
		doc     : 'If set to true Local Recording feature will be enabled.',
		format  : 'Boolean',
		default : false
	},

	highPassFilterFreq :
	{
		doc     : 'Default freq value for audio output high pass filter.',
		format  : 'nat',
		default : 250
	},

	audioOutputSupportedBrowsers :
	{
		doc     : 'White listing browsers that support audio output device selection.',
		format  : Object,
		default :
		{
			'microsoft edge' : '>=110',
			'chrome'         : '>=110',
			'chromium'       : '>=110',
			'firefox'        : '>=116'
		}
	},
	requestTimeout :
	{
		doc     : 'The Socket.io request timeout.',
		format  : 'nat',
		default : 20000
	},
	requestRetries :
	{
		doc     : 'The Socket.io request maximum retries.',
		format  : 'nat',
		default : 3
	},
	transportOptions :
	{
		doc     : 'The Mediasoup transport options.',
		format  : Object,
		default : {
			tcp : true
		}
	},
	// audio options
	autoGainControl :
	{
		doc     : 'Auto gain control enabled.',
		format  : 'Boolean',
		default : true
	},
	echoCancellation :
	{
		doc     : 'Echo cancellation enabled.',
		format  : 'Boolean',
		default : true
	},
	noiseSuppression :
	{
		doc     : 'Noise suppression enabled.',
		format  : 'Boolean',
		default : true
	},
	voiceActivatedUnmute :
	{
		doc     : 'Automatically unmute speaking above noiseThreshold.',
		format  : 'Boolean',
		default : false
	},
	noiseThreshold :
	{
		doc     : 'This is only for voiceActivatedUnmute and audio-indicator.',
		format  : 'int',
		default : -60
	},
	sampleRate :
	{
		doc     : 'The audio sample rate.',
		format  : [ 8000, 16000, 24000, 44100, 48000 ],
		default : 48000
	},
	channelCount :
	{
		doc     : 'The audio channels count.',
		format  : [ 1, 2 ],
		default : 1
	},
	sampleSize :
	{
		doc     : 'The audio sample size count.',
		format  : [ 8, 16, 24, 32 ],
		default : 16
	},
	opusStereo :
	{
		doc     : 'If OPUS FEC stereo be enabled.',
		format  : 'Boolean',
		default : false
	},
	opusDtx :
	{
		doc     : 'If OPUS DTX should be enabled.',
		format  : 'Boolean',
		default : true
	},
	opusFec :
	{
		doc     : 'If OPUS FEC should be enabled.',
		format  : 'Boolean',
		default : true
	},
	opusPtime :
	{
		doc     : 'The OPUS packet time.',
		format  : [ 3, 5, 10, 20, 30, 40, 50, 60 ],
		default : 20
	},
	opusMaxPlaybackRate :
	{
		doc     : 'The OPUS playback rate.',
		format  : [ 8000, 16000, 24000, 44100, 48000 ],
		default : 48000
	},
	// audio presets profiles
	audioPreset :
	{
		doc     : 'The audio preset',
		format  : 'String',
		default : 'conference'
	},
	audioPresets :
	{
		doc     : 'The audio presets.',
		format  : Object,
		default :
		{
			conference :
			{
				name                 : 'Conference audio',
				autoGainControl      : true, // default : true
				echoCancellation     : true, // default : true 
				noiseSuppression     : true, // default : true 
				// Automatically unmute speaking above noiseThreshold
				voiceActivatedUnmute : false, // default : false 
				// This is only for voiceActivatedUnmute and audio-indicator
				noiseThreshold       : -60, // default -60
				// will not eat that much bandwidth thanks to opus
				sampleRate           : 48000, // default : 48000 and don't go higher
				// usually mics are mono so this saves bandwidth
				channelCount         : 1, // default : 1
				sampleSize           : 16, // default : 16
				// usually mics are mono so this saves bandwidth
				opusStereo           : false, // default : false
				opusDtx              : true, // default : true / will save bandwidth 
				opusFec              : true, // default : true / forward error correction
				opusPtime            : 20, // minimum packet time (10, 20, 40, 60)
				opusMaxPlaybackRate  : 48000 // default : 48000 and don't go higher
			},
			hifi :
			{
				name                 : 'HiFi streaming',
				autoGainControl      : false, // default : true
				echoCancellation     : false, // default : true 
				noiseSuppression     : false, // default : true 
				// Automatically unmute speaking above noiseThreshold
				voiceActivatedUnmute : false, // default : false 
				// This is only for voiceActivatedUnmute and audio-indicator
				noiseThreshold       : -60, // default -60
				// will not eat that much bandwidth thanks to opus
				sampleRate           : 48000, // default : 48000 and don't go higher
				// usually mics are mono so this saves bandwidth
				channelCount         : 2, // default : 1
				sampleSize           : 16, // default : 16
				// usually mics are mono so this saves bandwidth
				opusStereo           : true, // default : false
				opusDtx              : false, // default : true / will save bandwidth 
				opusFec              : true, // default : true / forward error correction
				opusPtime            : 60, // minimum packet time (10, 20, 40, 60)
				opusMaxPlaybackRate  : 48000 // default : 48000 and don't go higher
			}
		}
	},

	autoMuteThreshold :
	{
		doc : `It sets the maximum number of participants in one room that can join unmuted.
The next participant will join automatically muted.
Set it to 0 to auto mute all.
Set it to negative (-1) to never automatically auto mute but use it with caution, 
full mesh audio strongly decrease room capacity!`,
		format  : 'nat',
		default : 5
	},
	autoMuteThresholds :
	{
		doc     : 'Maps the autoMuteThreshold depending on the room name.',
		format  : Object,
		default : {}
	},
	background :
	{
		doc      : 'The page background image URL',
		format   : 'String',
		default  : 'images/background.jpg',
		nullable : true
	},

	defaultLayout :
	{
		doc     : 'The default layout.',
		format  : [ 'democratic', 'filmstrip' ],
		default : 'filmstrip'
	},

	defaultRecorderMimeType :
	{
		doc     : 'The default mimeType for recorder.',
		format  : 'String',
		default : 'video/webm'
	},

	enableBeforeUnloadAlert :
	{
		doc     : 'If true, alert will come while leaving page.',
		format  : 'Boolean',
		default : true
	},

	enableAccessTokenInput :
	{
		doc     : 'If true, joinDialog will show field to enter token',
		format  : 'Boolean',
		default : false
	},

	selectWebcamOnButtonBarEnabled :
	{
		doc     : 'If false, the select webcam button on the control bar is not available.',
		format  : 'Boolean',
		default : true
	},

	snapshotsEnabled :
	{
		doc     : 'If false, the snapshot option is not available.',
		format  : 'Boolean',
		default : true
	},

	markersEnabled :
	{
		doc     : 'If false, the markers in recordings option is not available.',
		format  : 'Boolean',
		default : true
	},

	drawingEnabled :
	{
		doc     : 'If false, the drawing option is not available.',
		format  : 'Boolean',
		default : true
	},

	buttonControlBar :
	{
		doc     : 'If true, the media control buttons will be shown in separate control bar, not in the ME container.',
		format  : 'Boolean',
		default : false
	},

	drawerOverlayed :
	{
		doc : `If false, will push videos away to make room for side drawer.
If true, will overlay side drawer over videos.`,
		format  : 'Boolean',
		default : true
	},

	hiddenControls :
	{
		doc     : 'If true, control buttons will auto-hide',
		format  : 'Boolean',
		default : false
	},

	notificationPosition :
	{
		doc     : 'The horizontal position of the notifications on horizontal screens.',
		format  : [ 'left', 'right' ],
		default : 'left'
	},

	notificationPositionV :
	{
		doc     : 'The vertical position of the notifications on horizontal screens.',
		format  : [ 'top', 'bottom' ],
		default : 'bottom'
	},

	notificationPositionVerticalScreen :
	{
		doc     : 'The horizontal position of the notifications on vertical screens.',
		format  : [ 'left', 'right' ],
		default : 'left'
	},

	notificationPositionVerticalScreenV :
	{
		doc     : 'The vertical position of the notifications on vertical screens.',
		format  : [ 'top', 'bottom' ],
		default : 'top'
	},

	playNotificationSounds :
	{
		doc     : 'If false, notification sounds will not be played',
		format  : 'Boolean',
		default : true
	},

	notificationSounds :
	{
		doc : `It sets the notifications sounds.
Valid keys are: 'parkedPeer', 'parkedPeers', 'raisedHand', 
'chatMessage', 'sendFile', 'newPeer' and 'default'.
Not defining a key is equivalent to using the default notification sound.
Setting 'play' to null disables the sound notification.		
`,
		format  : Object,
		default :
		{
			chatMessage : {
				play : '/sounds/notify-chat.mp3'
			},
			raisedHand : {
				play : '/sounds/notify-hand.mp3'
			},
			default : {
				delay : 5000, // minimum delay between alert sounds [ms]
				play  : '/sounds/notify.mp3'
			}
		}
	},

	hideTimeout :
	{
		doc     : 'Timeout for auto hiding the topbar and the buttons control bar.',
		format  : 'int',
		default : 3000
	},
	maxButtons :
	{
		doc     : 'The maximum number of buttons before grouping.',
		format  : 'nat',
		default : 4
	},
	maxButtonsMobile :
	{
		doc     : 'The maximum number of buttons before grouping on mobile.',
		format  : 'nat',
		default : 3
	},
	lastN :
	{
		doc     : 'The maximum number of participants that will be visible in as speaker.',
		format  : 'nat',
		default : 4
	},
	lastNs :
	{
		doc     : 'Maps the lastN depending on the room name.',
		format  : Object,
		default : {}
	},
	mobileLastN :
	{
		doc     : 'The maximum number of participants that will be visible in as speaker for mobile users.',
		format  : 'nat',
		default : 1
	},
	mobileLastNs :
	{
		doc     : 'Maps the mobileLastN depending on the room name.',
		format  : Object,
		default : {}
	},
	maxLastN :
	{
		doc     : 'The highest number of lastN the user can select manually in the user interface.',
		format  : 'nat',
		default : 4
	},
	maxLastNAdmin :
	{
		doc     : 'The highest number of lastN the ADMIN user can select manually in the user interface.',
		format  : 'nat',
		default : 4
	},
	noGuestDefaultName :
	{
		doc     : 'If true, GUEST user names are not allowed.',
		format  : 'Boolean',
		default : true
	},
	lockLastN :
	{
		doc     : 'If true, the users can not change the number of visible speakers.',
		format  : 'Boolean',
		default : true
	},
	roomsWith3d :
	{
		doc     : 'Enables the possibilty of setting 3d mode based on the room name.',
		format  : Object,
		default : {}
	},
	roomsForEvents :
	{
		doc     : 'Enables functions for events for rooms',
		format  : Object,
		default : {}
	},
	showLogoFullScreen :
	{
		doc     : 'If false hides logo on full screen',
		format  : 'Boolean',
		default : true
	},
	showNameFullScreen :
	{
		doc     : 'If false hides name on full screen',
		format  : 'Boolean',
		default : true
	},
	logo :
	{
		doc      : 'If not null, it shows the logo loaded from the specified URL, otherwise it shows the title.',
		format   : 'url',
		default  : 'images/logo.svg',
		nullable : true
	},
	logos :
	{
		doc     : 'Maps the logos depending on the room name.',
		format  : Object,
		default : {}
	},
	logoHeightFactors :
	{
		doc     : 'Maps the logo height scale depending on the room name. If not given will be 1',
		format  : Object,
		default : {}
	},
	title :
	{
		doc     : 'The title to show if the logo is not specified.',
		format  : 'String',
		default : 'medVC'
	},
	titles :
	{
		doc     : 'Maps the titles depending on the room name.',
		format  : Object,
		default : {}
	},
	infoTooltipText :
	{
		doc     : 'Informative text on the join page. If empty hidden.',
		format  : 'String',
		default : ''
	},
	infoTooltipLink :
	{
		doc     : 'Informative text link site on the join page. If empty hidden.',
		format  : 'String',
		default : ''
	},
	infoTooltipDesc :
	{
		doc     : 'Informative text description on the join page. If empty hidden.',
		format  : 'String',
		default : ''
	},
	supportUrl :
	{
		doc      : 'The service & Support URL; if `null`, it will be not displayed on the about dialogs.',
		format   : 'url',
		default  : 'https://support.example.com',
		nullable : true
	},
	privacyUrl :
	{
		doc      : 'The privacy and data protection external URL or local HTML path.',
		format   : 'String',
		default  : 'privacy/privacy.html',
		nullable : true
	},

	theme :
	{
		doc     : 'UI theme elements colors.',
		format  : Object,
		default :
		{
			palette :
			{
				primary :
				{
					main : '#313131'
				}
			},
			overrides :
			{
				MuiAppBar :
				{
					colorPrimary :
					{
						backgroundColor : '#313131'
					}
				},

				MuiButton :
				{
					containedPrimary :
					{
						backgroundColor : '#5F9B2D',
						'&:hover'       :
						{
							backgroundColor : '#5F9B2D'
						}
					},
					containedSecondary :
					{
						backgroundColor : '#f50057',
						'&:hover'       :
						{
							backgroundColor : '#f50057'
						}
					}

				},

				/*
				MuiIconButton :
				{
					colorPrimary :
					{
						backgroundColor : '#5F9B2D',
						'&:hover'	   :
						{
							backgroundColor : '#5F9B2D'
						}
					},
					colorSecondary :
					{
						backgroundColor : '#f50057',
						'&:hover'	   :
						{
							backgroundColor : '#f50057'
						}
					}

				},
				*/

				MuiFab :
				{
					primary :
					{
						backgroundColor : '#518029',
						'&:hover'       :
						{
							backgroundColor : '#518029'
						},
						'&:disabled' : {
							color           : '#999898',
							backgroundColor : '#323131'
						}

					},

					secondary :
					{
						backgroundColor : '#f50057',
						'&:hover'       :
						{
							backgroundColor : '#f50057'
						},
						'&:disabled' : {
							color           : '#999898',
							backgroundColor : '#323131'
						}

					}

				},

				MuiBadge :
				{
					colorPrimary :
					{
						backgroundColor : '#5F9B2D',
						'&:hover'       :
						{
							backgroundColor : '#518029'
						}
					}
				}
			},
			typography :
			{
				useNextVariants : true
			}
		}
	}
});

function formatDocs()
{
	function _formatDocs(docs: any, property: string | null, schema: any)
	{
		if (schema._cvtProperties)
		{
			Object.entries(schema._cvtProperties).forEach(([ name, value ]) =>
			{
				_formatDocs(docs, `${property ? `${property}.` : ''}${name}`, value);
			});

			return docs;
		}
		else if (property)
		{
			docs[property] =
			{
				doc     : schema.doc,
				format  : JSON.stringify(schema.format, null, 2),
				default : JSON.stringify(schema.default, null, 2)
			};
		}

		return docs;
	}

	return _formatDocs({}, null, configSchema.getSchema());
}

function formatJson(data: string)
{
	return data ? `\`${data.replace(/\n/g, '')}\`` : '';
}

function dumpDocsMarkdown()
{
	let data = `# ![medvc logo](/app/public/images/logo.svg) App Configuration properties list:

| Name | Description | Format | Default value |
| :--- | :---------- | :----- | :------------ |
`;

	Object.entries(formatDocs()).forEach((entry: [string, any]) =>
	{
		const [ name, value ] = entry;

		data += `| ${name} | ${value.doc.replace(/\n/g, ' ')} | ${formatJson(value.format)} | \`${formatJson(value.default)}\` |\n`;
	});

	data += `

---

*Document generated with:* \`yarn gen-config-docs\` *from:* [config.ts](src/config.ts).
`;

	return data;
}

function dumpExampleConfigJs()
{
	let data = `/**
 * medVC App Configuration
 *
 * The configuration documentation is available also:
 * - in the app/README.md file in the source tree
 * - visiting the /?config=true page in a running instance
 */

// eslint-disable-next-line
var config = {
`;

	Object.entries(formatDocs()).forEach((entry: [string, any]) =>
	{
		// eslint-disable-next-line
		let [ name, value ] = entry;

		if (name.includes('.'))
			name = `'${name}'`;

		data += `\n\t// ${value.doc.replace(/\n/g, '\n\t// ')}
\t${name} : ${value.default},
`;
	});

	data += `};

// Generated with: \`yarn gen-config-docs\` from app/src/config.ts
`;

	return data;
}

// run the docs generator
if (typeof window === 'undefined')
{
	import('fs').then((fs) =>
	{
		fs.writeFileSync('public/config/README.md', dumpDocsMarkdown());
		fs.writeFileSync('public/config/config.example.js', dumpExampleConfigJs());
	});
}

//
let config: any = {};
let configError = '';

// Load config from window object
if (typeof window !== 'undefined' && (window as any).config !== undefined)
{
	configSchema.load((window as any).config);
}

// Perform validation
try
{
	// ASTAGOR
	// configSchema.validate({ allowed: 'strict' });
	config = configSchema.getProperties();
}
catch (error: any)
{
	configError = error.message;
}

// Override the window config with the validated properties.
if (typeof window !== 'undefined')
{
	(window as any)['config'] = config;
}

export {
	configSchema,
	config,
	configError,
	formatDocs
};
